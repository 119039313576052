import React from 'react';

import { log } from '../../resources';
import { ErrorPageUI } from '../ErrorPageUI';

interface State {
  hasError: boolean;
}

interface Props {
  children?: React.ReactNode;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    log({
      level: 'error',
      message: `${error.name}: ${error.message} \n ${errorInfo.componentStack}`,
    });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <ErrorPageUI
          onButtonClick={() => {
            this.setState({ hasError: false });
            window.location.pathname = '/';
          }}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
