import qs from 'qs';

export class FetchWrapperError<T> {
  constructor(
    public response: Response,
    public data: T,
  ) {}

  getSummary() {
    return `[FetchWrapperError] Resource: ${this.response.url}, Status: ${
      this.response.status
    } ${this.response.statusText}, with error response data: ${JSON.stringify(
      this.data ?? '',
    )}`;
  }
}

type FetchOptions = RequestInit & {
  params?: Record<string, any>;
};

type FetchResponse<T> = {
  response: Response;
  data: T;
};

export const isFetchWrapperError = <T = any>(
  error: any,
): error is FetchWrapperError<T> => error instanceof FetchWrapperError;

const fetchRequest = async (url: string, options?: FetchOptions) => {
  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      throw response;
    }

    return response;
  } catch (error: any) {
    if (error instanceof Response) {
      throw new FetchWrapperError(
        error,
        await error.json().catch(() => undefined),
      );
    } else {
      console.log(
        `[fetchRequest] Error while fetching ${url}: ${error.message}`,
      );
      throw new Error(
        `[fetchRequest] Error while fetching ${url}: ${error.message} \n ${JSON.stringify(error, null, 4)}`,
      );
    }
  }
};

export const fetchWrapper = {
  async get<T>(url: string, options?: FetchOptions): Promise<FetchResponse<T>> {
    if (options?.params) {
      url += `${qs.stringify(options.params, { addQueryPrefix: true })}`;
      delete options.params;
    }

    const response = await fetchRequest(url, options);

    const data = (await response.json().catch(() => undefined)) as T;

    return {
      response,
      data,
    };
  },

  async post<T>(
    url: string,
    body: any,
    options?: RequestInit,
  ): Promise<FetchResponse<T>> {
    const requestOptions: RequestInit = {
      ...options,
      method: 'POST',
      headers: {
        ...(options?.headers || {}),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    const response = await fetchRequest(url, requestOptions);

    const data = (await response.json().catch(() => undefined)) as T;

    return {
      response,
      data,
    };
  },

  async head(
    url: string,
    options?: RequestInit & { params?: Record<string, any> },
  ): Promise<Response> {
    if (options?.params) {
      const params = new URLSearchParams(options.params);
      url += `?${params.toString()}`;
      delete options.params;
    }

    const response = await fetchRequest(url, { ...options, method: 'HEAD' });

    return response;
  },
};
